header {
    text-align: center;
    position: fixed;
    top: 0;
    margin: auto;
    height: 75px;
    width: 100%;
    background-color: #666;
}

header h1 {
    position: fixed center;
    /* left: 38vw;
    margin: auto; */
}

.app {
  width: 100vw;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

main {
  min-height: 75vh;
  margin-top: 75px;
}

footer {
  font-size: 8px;
  text-align: center;
  margin-top: 10px;
}

.copyright {
  font-size: 10px;
}

footer a {
  text-decoration: none;
  color: #353EE5;
  font-size: 8px;
}

footer a:hover {
  cursor: pointer;
  color: #FF7D12;
}

footer a:visited {
  color: rgb(23, 104, 82);
}

/* menu {
    display: block;
} */

/* nav {
    display: block;
} */

.nav-menu {
    width: 100%;
    height: 100%;
    font-family: 'Londrina Outline', cursive;
    display: block;
}

/* .nav-menu {
    height: 100%;
    width: 100%;
    background-color: #666;
} */

.nav-menu a {
    display: block;
    text-decoration: none;
    font-size: 30px;
    color: #fff;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 25px;
  height: 25px;
  left: 36px;
  top: 25px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #2781FF;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #00FFC4;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  /* background: #373a47; */
  background: #123456;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  background-color: #123456;
  padding: 0.8em;
  display: block;
}

/* Individual item */
.bm-item {
  /* display: inline-block; */
  display: block;
}

/* Styling of overlay */
.bm-overlay {
  /* background: rgba(0, 0, 0, 0.3); */
  background: orange;
}