.climbing-track {
    margin-top: 80px;
    text-align: center;
    line-height: 1.5;
}

.climbs {
  background-color: #666;
  color: white;
  width: 75%;
  height: 300px;
  margin: auto;
  padding: 2px;
  margin-bottom: 10px;
}
.add {
  border-radius: 50%;
  background-color: #666;
  color: white;
  font-size: 30px;
  width: 50px;
  height: 50px;
  margin: auto;
  margin-top: 20px;
}