.landing-page {
    margin-top: 20px;
    text-align: center;
    line-height: 1.5;
}

section {
  min-height: 200px;
  padding: 30px 15px;
  background-color: #bbb;
}

section:nth-child(even) {
  background-color: #ddd;
}

p {
  margin: 0 auto;
  max-width: 650px;
}

p + p {
  margin-top: 15px;
}