.signuplogin-forms {
  background: #aaa;
  margin-top: 20px;
  text-align: center;
  line-height: 1.5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.signup-form, .login-form {
    display: block;
    margin: auto;
    width: 80vw;
    padding: 5px;
    text-align: center;
}

.signup-form {
    margin-bottom: 30px;
}

.signuplogin-forms label, input, button {
    display: block;
    margin: auto;
    padding: 2px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.signuplogin-forms input {
    height: 30px;
    padding: 2px;
    width: 50vw;
}

.exists {
    font-size: 15px;
}

.signuplogin-forms h2 {
    font-size: 20px;
}

.login-form h2 {
    margin-top: -5px;
}